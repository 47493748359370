import React from 'react';
import Header from './Header';
import Services from './Services';
import Slideshow from './Slideshow';
import Banner from './Banner';
import Intro from './Intro';
import Form from './Form';
import InfoContainer from './InfoContainer';
import List from './List'

function App() {
  return (
    <div className="App">
     <Header />
     <Slideshow />
     <Services />
     <Intro heading='MW Car And Commercial' text='If you’re looking for a reliable car garage in North Wales and Chester, look no further than MW Car and Commercial, specialists in a wide range of garage services including Car Servicing, MOT, Car Repairs, Engine Diagnostics and Tyres in North Wales and Chester.
Established in 2011, we provide an honest and reliable service at all times, proud to be recognised as a highly reputable car garage in North Wales and Chester.
Located at Unit 5 Broughton Industrial Estate, Broughton Mills Road, we are ideally based to serve the local North Wales and Chester community, undertaking all types of car garage services, from Car Servicing to MOT.'/>
     <Banner imagelocation='manufacturers'/>
     <Intro heading='Why Choose MW Car and Commercial?' text='At MW Car and Commercial, we have worked hard to build a reputation for offering a trusted and “value for money” service to all our customers in North Wales and Chester. We understand the importance of good customer service, with the majority of our work gained through positive word of mouth and repeat custom.'/>
     <Intro heading='Qualified & Experienced Car Mechanics' text='Our North Wales and Chester car mechanics are fully qualified and experienced in working with all types of vehicles, completing all work to the highest of standards at a very competitive price. Our mechanics are on hand to offer free, valuable advice on Car Servicing, MOT, and Car Repairs, and are happy to answer any questions you may have regarding your vehicle.
When working on your car, we will clearly explain all the options available to you, along with our personal recommendations. At MW Car and Commercial, we always offer a quote upfront and always make sure we obtain your approval prior to any work being carried out.
We understand the frustration caused by not having your car, which is why our North Wales and Chester car mechanics work efficiently to get your car back on the road in next to no time. '/> 
      
      <List 
      item1='Car Servicing' 
      item2='Car Repairs' 
      item3='Air Conditioning' 
      item4='Clutch Replacements'
      item5='Car Batteries' 
      item6='MOT Repairs' 
      item7='Engine Repair & Rebuilds' 
      item8='Engine Tuning'
      item9='Car Body Repairs' 
      item10='Dent Removal' 
      item11='Tyres' 
      item12='Exhausts' 
      item13='Brakes'
      item14='Gearbox Repairs' 
      item15='Engine Diagnostics' 
      item16='Breakdown Recovery'
      item17='Car Valeting' 
      item18='Tow Bar Fitting'
      />
    <Banner imagelocation='strip' />
    
    <InfoContainer />
    <Banner imagelocation='MWCarMap3'/>
    </div>
  );
}

export default App;
