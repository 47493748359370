import React, { Component } from 'react';

class List extends Component {
    render() {
        const styles = {
            display:"flex",
            flexFlow: "row wrap",
            justifyContent:"center",
            fontSize:"1.5em",
            padding:"20px"
          };
        return (
            <div style={styles}>
                <ul >
                    <li>{this.props.item1}</li>
                    <li>{this.props.item2}</li>
                    <li>{this.props.item3}</li>
                    <li>{this.props.item4}</li>
                    <li>{this.props.item5}</li>
                    <li>{this.props.item6}</li>
                    <li>{this.props.item7}</li>
                    <li>{this.props.item8}</li>
                    <li>{this.props.item9}</li>
                </ul>
                <ul>
                    <li>{this.props.item10}</li>
                    <li>{this.props.item11}</li>
                    <li>{this.props.item12}</li>
                    <li>{this.props.item13}</li>
                    <li>{this.props.item14}</li>
                    <li>{this.props.item15}</li>
                    <li>{this.props.item16}</li>
                    <li>{this.props.item17}</li>
                    <li>{this.props.item18}</li>
                    
                </ul> 
            </div>
        );
    }
}

export default List;