import React, { Component } from 'react';
import logo from './images/logo.png';
import './Header.css';

class Header extends Component {
    render() {
        return (
            <div className="head">
                <img src={logo} alt=''/>
            </div>
        );
    }
}

export default Header;
