import React, { Component } from 'react';
import './Banner.css';
class Banner extends Component {
    render() {
        return (
            <div className='manu'>
                <img src={require('./images/' + this.props.imagelocation + '.jpg')} alt=''/> 
            </div>
        );
    }
}

export default Banner;