import React, { useState, useEffect } from 'react';
import slide1 from './images/slide_1.jpg';
import slide2 from './images/slide_2.jpg';
import slide3 from './images/slide_3.jpg';
import './Slideshow.css';


const Slideshow = () => {
  const [slide, setSlideImage] = useState(slide1);
  useEffect(() => {
    setTimeout(()=>{
      switch (slide) {
        case slide1:setSlideImage(slide2)
          break
        case slide2:setSlideImage(slide3)
          break
        case slide3:setSlideImage(slide1)
          break          
        default:
          console.log(`Sorry, we are out of ${slide}.`);
      }
      
      
      },5000)
  });
  
    
  return (
    <div className="slides">
        
        <img src={slide} alt={slide}/>
        
        <h3>• Free Courtesy Car• Friendly Service• Free MOT Re-Test!• Great Rates On Tyres• Keep Your Warranty• Great Discounts!</h3>
    </div>
  );
};

export default Slideshow;