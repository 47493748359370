import React, { Component } from 'react';
import InfoSnippets from './InfoSnippet';
import './InfoContainer.css';
import Form from './Form';
import MWCarMap from './images/MWCarMap.jpg';
class InfoContainer extends Component {
    render() {
        return (
            <div className='infocontainer'>
            <div>
                <InfoSnippets title="Opening hours"/>
                <InfoSnippets info1='Monday:    9:00-17:00'/>
                <InfoSnippets info2='Tuesday:   9:00-17:00'/>
                <InfoSnippets info3='Wednesday: 9:00-17:00'/>
                <InfoSnippets info4='Thursday:  9:00-17:00'/>
                <InfoSnippets info5='Friday:    9:00-17:00'/>
                <InfoSnippets info6='Saturday:  9:00-17:00'/>
                <InfoSnippets info7='Sunday:    9:00-17:00'/>                
            </div>
            <div>
                <Form />
            </div>
            <div>
                <InfoSnippets title='Contact Us:'/>
                <InfoSnippets info2='01244 660 999'/>
                <InfoSnippets info3='info@mwcarandcommercial.co.uk'/>
                <InfoSnippets info4='MW Car & Commercial Ltd'/>
                <InfoSnippets info5='Broughton Industrial Estate'/>
                <InfoSnippets info6='Broughton Mills Rd, Bretton' />
                <InfoSnippets info7='Chester CH4 0BY' />
                <InfoSnippets gmap='https://goo.gl/maps/LJPzBHs851hCPDq16' googlemaps='>Google Maps<'/>
                

            </div>
            </div>
        );
    }
}

export default InfoContainer;