import React, { Component } from 'react';
import './InfoSnippets.css';


class InfoSnippet extends Component {
    render() {
        return (
            <div className='infosnippet'>
               <h1>{this.props.title}</h1> 
               <div>{this.props.info1}</div> 
               <div>{this.props.info2}</div>
               <div>{this.props.info3}</div>
               <div>{this.props.info4}</div>
               <div>{this.props.info5}</div>
               <div>{this.props.info6}</div>
               <div>{this.props.info7}</div>
               <a href={this.props.gmap}>{this.props.googlemaps}</a>
            </div>
        );
    }
}

export default InfoSnippet;