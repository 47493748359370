import React, { Component } from 'react';
import './Intro.css';

class Intro extends Component {
    render() {
        return (
            <div className='intro'>
                <h1>{this.props.heading}</h1>
                <p>{this.props.text}</p>  
            </div>
        );
    }
}

export default Intro;  
