import React, { Component } from 'react';
import mot from './images/mot.jpg';
import repair from './images/repair.jpg';
import servicing from './images/servicing.jpg';
import './Services.css';

class Services extends Component {
    render() {
        return (
            <div className="images">
                <img src={mot} alt=''/>
                <img src={repair} alt=''/>
                <img src={servicing} alt=''/>   
            
            </div>

        );
    }
}

export default Services;